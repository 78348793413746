import { defineStore } from "pinia";

export const useSidebarStore = defineStore("useSidebar", () => {
    const open = ref(false);

    function openSidebar() {
        open.value = true;
    }

    function closeSidebar() {
        open.value = false;
    }

    return {
        open,
        closeSidebar,
        openSidebar,
    };
});
